import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { useI18next, I18nextContext } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

import SEO from '@components/seo';
import '../theme/truck-menu.css';

import MenuImageES from '@images/truck-menu-es.jpg';
import MenuImageEN from '@images/truck-menu-en.jpg';

const LanguageList = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  & > li {
    list-style-type: none;
    display: inline-block;
    font-size: 16px;
    &:not(:last-of-type)::after {
      content: "|";
      margin: 0 10px;
    }
  }
`;

const MenuImage = styled.img`
  width: 100%;
  height: auto;
`;

const TruckPage = () => {
  // const { t } = useTranslation();
  const { languages, changeLanguage } = useI18next();
  const i18nextContext = useContext(I18nextContext);

  return (
    <>
      <SEO title="Pizzería Habana"/>
      <Container className="text-center">
        <LanguageList>
          {languages.map((lng) => (
            <li key={lng}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  changeLanguage(lng);
                }}>
                {lng === 'es' ? 'Español' : 'English'}
              </a>
            </li>
          ))}
        </LanguageList>

        <MenuImage src={i18nextContext.language === 'es' ? MenuImageES : MenuImageEN} alt="Menú" className="mb-4"/>
      </Container>
    </>
  );
};

export default TruckPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
